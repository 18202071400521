<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                  <el-button v-if="auth.save"
                    icon="el-icon-plus"
                    type="primary"
                    @click.stop="handleAddBtn"
                >新增</el-button>
                <!-- <el-row style="margin-bottom:15px;">
                    <el-col :span="6">
                        <div>
                            <el-input placeholder="请输入内容" v-model.trim="search_value" class="input-with-select">
                                <el-select style="width:120px;" v-model="search_type" slot="prepend" placeholder="请选择">
                                    <el-option v-for="item in searchTypeEnum" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </el-col>
                    <el-button style="margin-left:24px;" type="primary" @click.stop="handleSubmit">搜索</el-button>
                    <el-button @click.stop="handleClear">清空</el-button>
                </el-row> -->
                <el-table :data="tableData">
                    <el-table-column label="邮件主题" prop="title"></el-table-column>
                    <el-table-column label="发件邮箱" prop="sendEmail"></el-table-column>
                    <el-table-column label="发送量" prop="totalNum">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                >{{scope.row.totalNum}}</el-button>
                            </div>
                        </template>

                    </el-table-column>
                    <el-table-column label="成功数量"  prop="successNum"></el-table-column>
                    <el-table-column label="失败数量"  prop="failNum"></el-table-column>
                    <el-table-column label="操作时间"  prop="createTime">
                        <template slot-scope="scope">
                            <div v-html="scope.row.createTime"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作人"  prop="createEmp"></el-table-column>

                  
                   
                    <!-- <el-table-column label="操作"
                        fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="true||auth.save"
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                >详情</el-button>
                             
                                <el-button
                                    v-if="true||auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>



<script>
// import { testdata } from "./data";


export default {
    name: 'EmailList',

    components: {},
    props: {},
    data() {
        return {
            tableData: [],
            search_value: "",
            search_type: 1,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            searchTypeEnum: [
                { name: "渠道编号", value: 1 },
                { name: "渠道名称", value: 2 },
                { name: "电话号码", value: 3 },
            ],
            auth:{
               delete:false 
            }
        };
    },
    created() {
        // console.log(testdata);
        // this.tableData = testdata.data


		this.auth.save= this.$hasAuthFor("api/cenEmail/saveSendEmail");



        this.auth.delete = this.$hasAuthFor("api/channel/deleteChannel"); //删除


        this.fetchData();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        // 点击 "新增"
		handleAddBtn() {
			this.$router.push({
				path: "/tool/emailList/sendEmail",
				// query: {sys_id: this.SP_.search_sys}
			});
		},
        // 详情
        handleDetail(data){
            // 渠道详情

            // this.$router.push({
			// 	path: `/customer/channelPhoneList/Detail`,
			// 	query: {id: data.id,channelCode:data.channelCode}
			// });

            this.$router.push({
				path: `/tool/emailList/Detail`,
				query: {id: data.id}
			});

            // 渠道新增 编辑

            // this.$router.push({
			// 	path: `/customer/channelPhoneList/add`,
			// 	query: {id: data.id}
			// });

            // 在线签署渠道协议列表
            // this.$router.push({
			// 	path: `/customer/channelLineList`,
			// 	query: {id: data.id}
			// });

        },
        // 点击表格行操作按钮: 删除
        async handleDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("请先确定该渠道在各业务系统中是否关联客户，删除后会同步删除业务系统中的渠道，您确定要删除么？", "删除渠道提示信息")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/channel/deleteChannel",
                    method: "post",
                    data: {
                        id: parseInt(data.id)
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        handleSubmit() {
            this.pageNum=1
            this.pageSize=10
            this.fetchData();
        },
        handleClear() {
            this.pageNum=1
            this.pageSize=10
            this.search_value = "";
            this.search_type = 1;
            this.fetchData();
        },
        async fetchData() {
            const loading = this.$loading();
            const res = await this.$axios({
                // url: "/api/channel/queryChannelPhoneList",
                url: "/api/cenEmail/queryCenEmailList",
                method: "post",
                data: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    // search_type: this.search_type,
                    // search_value: this.search_value,
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.tableData = res.data.sendEmailList || [];
                this.total = res.data.count || 0;
            }else{
                this.$message({
                    message: res.msg || "获取数据失败",
                    type: "warning"
                });
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>
</style>